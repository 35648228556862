<template>
  <div class="swiper-container home-swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in swiperList" :key="index">
        <video-player
          :ref="`video` + index"
          :options="item"
          :optionsIndex="index"
          playIn="mobile"
          @singleTap="singleTap"
          @doubleTap="doubleTap"
          @changeFocus="onChangeFocus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VConsole from "vconsole";
import VideoPlayer from "@/components/videoPlayer/VideoPlayer";
import "swiper/css/swiper.min.css";
import Swiper from "swiper";
import options from "@/utils/options.js";

export default {
  data: function () {
    return {
      swiperList: [], //slider列表
      lastIndex: 0, //上一个slider的index索引
      swiperHandle: null, //swiper句柄
      stUserInfo: null, //用户信息
      page: 1, //当前页面
    };
  },
  components: {
    VideoPlayer,
    Swiper,
  },
  methods: {
    singleTap() {
      this.$refs["video" + this.lastIndex][0].togglePlayer();
    },
    doubleTap(e) {
      this.$refs["video" + this.lastIndex][0].moniterDoubleClick(e);
    },
    //swiper.slides表示总共个数， activeIndex表示当前索引
    removeUnwanted(swiper) {
      if (swiper.activeIndex + 2 >= swiper.slides.length) {
        this.swiperList.push(options.options6);
        this.swiperList.push(options.options7);
        this.swiperList.push(options.options8);
        // this.swiperList = this.swiperList.splice(-5);
      }
      this.$nextTick(() => {
        this.swiperHandle.update();
      });
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        beacon.getAccountInfo({}, (accountInfo) => {
          this.stUserInfo = accountInfo
          resolve(accountInfo);
        });
      });
    },
    initSwiper() {
      const that = this;
      let timer = null; // 定时器，记录视频停留时长
      let second = 0; // 停留时长
      timer = window.setInterval(() => {
        second += 0.1
      }, 100)
      this.swiperHandle = new Swiper(".swiper-container", {
        loop: false,
        lazyLoading: true,
        direction: "vertical",
        on: {
          slideChangeTransitionEnd() {
            if (timer) {
              // 当登陆后，记录视频浏览记录
              if (that.stUserInfo.AID) {
                that.API.dataStatistics.videoBuriedPoint({
                  branchVideoStatus: that.$refs["video" + that.lastIndex][0].visitBranch ? 0 : 1,
                  completionRate: that.$refs["video" + that.lastIndex][0].seedingRate ? 0 : 1,
                  // stayLength: Number(second.toFixed(2)),
                  stayLength: Math.ceil(second),
                  headImg: that.stUserInfo.dtheadimgurl,
                  nickName: that.stUserInfo.dtnickname,
                  userId: that.stUserInfo.AID,
                  videoId: that.$refs["video" + that.lastIndex][0].video.iId
                })
              }
              second = 0
              window.clearInterval(timer)
              timer = window.setInterval(() => {
                second += 0.1
              }, 100)
            }
            that.$refs["video" + that.lastIndex][0].pause();
            that.$refs["video" + this.activeIndex][0].play();
            that.lastIndex = this.activeIndex;
            // that.removeUnwanted(this);
            if (this.activeIndex >= this.slides.length - 3) {
              that.page += 1;
              that.getList();
            }
          },
        },
      });
    },
    async getList() {
      const stUserInfo = {
        vGUID: [],
        sDUA: "",
        iAccountId: this.stUserInfo ? this.stUserInfo.AID : 0, // 灯塔互动APP登陆后才有的id
      };
      const params = {
        stUserInfo: stUserInfo,
        iPageNum: this.page,
      };
      const { data } = await this.API.appSlider.getInteractiveVideoList(params);
      if (data.vInteractiveVideo.length == 0) {
        return;
      }
      let finalOptions = [];
      data.vInteractiveVideo.map((item) => {
        let tempObj = {
          stUserInfo: stUserInfo,
          video: { ...item },
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        };
        tempObj.video.poster = item.sPicUrl;
        tempObj.video.src = item.sVideoUrl;
        if (item.sUpdateContent) {
          const sUpdateContent = JSON.parse(item.sUpdateContent);
          console.log('sUpdateContent',sUpdateContent)
          tempObj.author = sUpdateContent.author; // 视频作者
          tempObj.branches = sUpdateContent.branches; // 视频元素
          tempObj.video.sDes = sUpdateContent.video.sDes || ""; // 视频描述
          tempObj.cardSettings = sUpdateContent.cardSettings || undefined; // 添加名片
        }
        finalOptions.push(tempObj);
      });
      console.log('finalOptions',finalOptions)
      // finalOptions=finalOptions.slice(0,1)
      this.swiperList.push(...finalOptions);
      this.$nextTick(() => {
        this.swiperList.map((item, index) => {
          this.$refs["video" + index][0].initVideoPlayer();
        });
        this.swiperHandle.update();
      });
    },
    listenMobile(funcName){
      lisenNative(funcName, async () => {
        this.$refs["video" + this.swiperHandle.activeIndex][0].play();
        //返回后查询当前视频作者信息，判断是否关注该作者
        this.updateAccountInfo();
        this.updateUserAttention();
      });
    },
    async updateAccountInfo(){
      this.stUserInfo = await this.getUserInfo();
      console.log("updateAccountInfo",this.stUserInfo);
      this.$store.commit('login/setStUserInfo',this.stUserInfo);
      this.swiperList.forEach((item,index)=>{
        this.$refs["video" + index][0].updateAccountInfo(this.stUserInfo)
      })
      
    },
    async updateUserAttention(){
      if (!this.stUserInfo||this.stUserInfo.AID<=0) {
        return
      }
      const stUserInfo = {
        iAccountId: this.stUserInfo ? this.stUserInfo.AID : 0, // 灯塔互动APP登陆后才有的id
      };
      const params = {
        stUserInfo: stUserInfo,
        iOtherAccountId: this.swiperList[this.swiperHandle.activeIndex].video.userId,
      };
      const data = await this.API.appSlider.getFeedUserInfo(params);
      if (data.status === 200) {
        let info = data.data.data;
        this.swiperList.forEach((item) => {
          if(item.video.userId==info.iAccountId){
            item.video.isAttention=info.isAttention;
          }
        });
      }
    },
    onChangeFocus(obj){
      console.log("onChangeFocus",obj);
      this.updateUserAttention();
    }
    
  },
  async mounted() {
    if (this.$route.query.consoleMode==1) {
      var vConsole = new VConsole();
    }
    
    await this.updateAccountInfo();
    //初始化swiper
    this.initSwiper();
    //获取列表
    this.getList();
    this.listenMobile("webviewShow");
    this.listenMobile("loginSuccess");
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
